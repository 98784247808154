import React from "react";
import {Input} from 'antd';
import {useNavigate} from "react-router-dom";

function VideoSearch({disableSearch = false}) {
	let navigate = useNavigate();
	let {Search} = Input;
	let searchQuery = new URLSearchParams(window.location.search).get('q');

	let onContentSearch = (searchText) => {
		let urlSearchParams = new URLSearchParams();
		urlSearchParams.append('q', searchText);

		//navigate to the search results page with the search query as a parameter
		//ensure that the search results page re-renders when the search query changes
		navigate(`/search?${urlSearchParams.toString()}`);
		// window.location.reload();
	};

	return <div className="mt-4">
		<Search placeholder="Search in videos" style={{width: 523}}
		        allowClear
		        onSearch={onContentSearch}
		        defaultValue={searchQuery}
		        disabled={disableSearch}
		/>
	</div>;
}

export default VideoSearch;