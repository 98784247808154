import React from 'react';
import {NavLink} from "react-router-dom";


function Header() {
	return <header
		className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-red-500 via-yellow-500 via-orange-500 to-blue-500">
		<NavLink to="/">Video Seeker</NavLink>
	</header>;
}

export default Header;