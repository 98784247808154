import React, {useRef} from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import VideoSearch from "../components/VideoSearch";
import {getVideoDataForId} from "../util/VideoHelper";

function VideoViewPage() {
	//keep track if the video is played for the first time
	let videoPlayed = useRef(false);
	let urlSearchParams = new URLSearchParams(window.location.search);

	//get video id from the search param 'v'
	let videoId = urlSearchParams.get('v');
	let video = getVideoDataForId(videoId);
	//get the time at which the video should start
	let timeToStart = urlSearchParams.get('t') || 0;

	const handleVideoPlay = (videoPlayEvent) => {
		//only if the video is played for the first time start the video at
		//the specified time if the time is less than the duration of the video.
		//if the video is played again, then start the video from the beginning
		if (videoPlayed.current === true) {
			return;
		}

		let videoElement = videoPlayEvent.target;
		let duration = videoElement.duration;

		videoElement.currentTime = timeToStart > duration ? duration : timeToStart;

		videoPlayed.current = true;
	}

	return (
		<div className="container mx-auto p-4">
			<Header/>
			<VideoSearch/>

			<section className="mt-4">
				<video poster={video.poster} src={video.src} controls width="100%" onPlay={handleVideoPlay}/>
				<div className="mt-2 font-semibold">{video.title}</div>
			</section>

			<Footer/>
		</div>
	);
}

export default VideoViewPage;