import React from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import VideoListing from "../components/VideoListing";
import VideoSearch from "../components/VideoSearch";
import {getVideosData} from "../util/VideoHelper";

function VideoListingPage() {
	let data = getVideosData();

	return (
		<div className="container mx-auto p-4">
			<Header/>
			<VideoSearch/>
			<VideoListing videoData={data}/>
			<Footer/>
		</div>
	);
}

export default VideoListingPage;