import {List} from "antd";
import React from "react";
import VideoTile from "./VideoTile";

function VideoListing({videoData}) {
	return <div className="mt-4">
		<List
			grid={{
				gutter: 16,
				xs: 1,
				sm: 2,
				md: 3,
				lg: 4,
				xl: 4,
				xxl: 4,
			}}
			dataSource={videoData}
			renderItem={(video) => (
				<List.Item>
					<VideoTile video={video}/>
				</List.Item>
			)}
			pagination={{
				pageSize: 9,
			}}
		/>
	</div>;
}

export default VideoListing;