import React from "react";
import VideoTile from "./VideoTile";
import {Link} from "react-router-dom";

function VideosMatchingQuery({videosData}) {
	return (videosData).map((video) => {
		//Display two columns, one for the video and one for the answer
		//the column for VideoTile should be 2/3rd of the width
		//and the column for answer should be 1/3rd of the width
		return (
			<div className="mt-4">
				<div className="grid grid-cols-3 gap-4">
					<div className="col-span-1">
						<VideoTile video={video} skipTitle={true}/>
					</div>
					<div className="col-span-2">
						<div className="font-bold">{video.title}</div>
						<h6 className="mt-1 font-semibold">Answer:</h6>
						<p>{video.answer}</p>

						<h6 className="mt-1 font-semibold">Timestamps: </h6>
						<ul>
							{video.timestamps.slice(0, 1).map((timestamp) => {
									let searchParams = new URLSearchParams();
									searchParams.set('v', video.id);
									//convert timestamp in mm:ss format to seconds.
									let timestampParts = timestamp.split(':');
									timestampParts[2] = timestampParts[2].split('.')[0];

									let timestampInSeconds = parseInt(timestampParts[1]) * 60 + parseInt(timestampParts[2]);
									searchParams.set('t', timestampInSeconds);

									return <li><Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`/view?${searchParams.toString()}`}>{timestamp}</Link></li>;
								}
							)}
						</ul>
					</div>
				</div>
			</div>
		);
	});
}

export default VideosMatchingQuery;