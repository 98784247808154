import './App.css';
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import './index.css';
import VideoListingPage from "./pages/VideoListingPage";
import VideoViewPage from "./pages/VideoViewPage";
import VideoSearchResultsPage from "./pages/VideoSearchResultsPage";

function App() {
	return <BrowserRouter>
		<Routes>
			<Route path='/' element={<VideoListingPage/>}/>
			<Route path='/search' element={<VideoSearchResultsPage/>}/>
			<Route path='/view' element={<VideoViewPage/>}/>
		</Routes>
	</BrowserRouter>
}

export default App;
