import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import VideoSearch from "../components/VideoSearch";
import Footer from "../components/Footer";
import {useSearchParams} from "react-router-dom";
import {getVideosWithAnswer} from "../util/VideoHelper";
import VideosMatchingQuery from "../components/VideosMatchingQuery";

function ResultsLoadingIndicator() {
	let videoDetailTileStyle = {width: '80%', height: '25px'};

	return <div className="grid grid-cols-3 gap-4 mt-2">
		<div className="col-span-1">
			<div className="bg-gray-200 animate-pulse" style={{width: '100%', height: '100px'}}></div>
		</div>
		<div className="col-span-1">
			<div className="bg-gray-200 animate-pulse" style={videoDetailTileStyle}></div>
			<div className="bg-gray-200 animate-pulse mt-2" style={videoDetailTileStyle}></div>
			<div className="bg-gray-200 animate-pulse mt-2" style={videoDetailTileStyle}></div>
		</div>
	</div>;
}

function VideoSearchResultsPage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(null);
	const [videosWithAnswer, setVideosWithAnswer] = useState([]);
	const [videosWithAnswerLoading, setVideosWithAnswerLoading] = useState(false);

	useEffect(() => {
		let q = searchParams.get('q');
		console.log(`Search query is ${q}`);
		setSearchQuery(q);
	}, [searchParams]);

	useEffect(() => {
		(async () => {
			if (searchQuery !== null && searchQuery.length > 0) {
				setVideosWithAnswerLoading(true);
				let videosWithAnswer = await getVideosWithAnswer(searchQuery);
				setVideosWithAnswerLoading(false);
				setVideosWithAnswer(videosWithAnswer);
			}
		})();
	}, [searchQuery]);

	return (
		<div className="container mx-auto p-4">
			<Header/>
			<VideoSearch disableSearch={videosWithAnswerLoading}/>

			{videosWithAnswerLoading === true && <>
				<div className="mt-4">
					Loading videos for the search query: <i>{searchQuery}</i>
				</div>
				<ResultsLoadingIndicator/>
			</>
			}

			{videosWithAnswerLoading === false && videosWithAnswer.length === 0 && searchQuery !== '' &&
				<div className="mt-4">
					No videos found for the search query: <i>{searchQuery}</i>
				</div>
			}

			{videosWithAnswerLoading === false && videosWithAnswer.length > 0 && <>
				<div className="mt-4">
					Found {videosWithAnswer.length} {videosWithAnswerLoading.length > 1 ? 'videos': 'video'} for the search query: <i>{searchQuery}</i>
				</div>
				<VideosMatchingQuery videosData={videosWithAnswer}/>
			</>
			}

			<Footer/>
		</div>
	);
}

export default VideoSearchResultsPage;