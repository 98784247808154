import {getVideoSearchUrl} from "./BackendApiHelper";

function getVideoContainerUrl() {
	return "https://inqutec-poc-demo.s3.me-south-1.amazonaws.com/video-seeker/videos/";
}

function getVideoThumbnailContainerUrl() {
	return "https://inqutec-poc-demo.s3.me-south-1.amazonaws.com/video-seeker/thumbnails/";
}

export function getVideoThumbnailUrl(videoId) {
	return `${getVideoThumbnailContainerUrl()}${videoId}.jpg`;
}

function getVideoSrcUrl(videoId) {
	return `${getVideoContainerUrl()}${videoId}.mp4`
}

function getVideosData() {
	let data = [
		{
			"id": "dJcuvZ-_n2Q",
			"title": "3 things to know about flu season"
		},
		{
			"id": "JY1qi3bf-Os",
			"title": "HIV Basics: Testing, Prevention, and Living with HIV"
		},
		{
			"id": "zc7yvvl5qTM",
			"title": "Zika Virus 101"
		},
		{
			"id": "U9e9__bjmnY",
			"title": "Plasma Leakage"
		},
		{
			"id": "wmOW091P2ew",
			"title": "What is Diabetes"
		},
		{
			"id": "W0GpIMNTPYg",
			"title": "What causes kidney stones? - Arash Shadman"
		},
		{
			"id": "-SPRPkLoKp8",
			"title": "How do your hormones work? - Emma Bryce"
		},
		{
			"id": "WW4skW6gucU",
			"title": "How mucus keeps us healthy - Katharina Ribbeck"
		},
	];

	data = data.map((video) => {
		video.poster = getVideoThumbnailUrl(video.id);
		video.src = getVideoSrcUrl(video.id);

		return video;
	});

	return data;
}

function getVideoDataForId(videoId) {
	let videosData = getVideosData();

	for (const videoData of videosData) {
		if (videoData.id == videoId) {
			return videoData;
		}
	}

	return undefined;
}

export async function getVideosWithAnswer(searchText) {
	let videoSearchUrl = getVideoSearchUrl(searchText);

	try {
		let response = await fetch(videoSearchUrl, {method: 'GET'});
		let responseData = await response.json();

		return responseData.data.data;
	} catch (error) {
		console.log(error);

		return [];
	}
}

export {getVideoContainerUrl, getVideoSrcUrl, getVideosData, getVideoDataForId};