import React from "react";
import { useNavigate } from 'react-router-dom';

const VideoTile = ({video, skipTitle}) => {
	const navigate = useNavigate();

	function handleVideoClick(event) {
		let videoId = event.target.id.split('video_')[1];
		navigate(`/view?v=${videoId}`);
	}

	return (
		<>
			<div className="video-player-container">
				<video
					className="video-player"
					src={video.src}
					poster={video.poster}
					controls={false}
					autoPlay={false}
					muted={true}
				/>
				<div className="video-player-overlay" id={"video_" + video.id} onClick={handleVideoClick}>
					<svg className="video-player-play" viewBox="0 0 24 24">
						<path fill="#fff" d="M8 5v14l11-7z"/>
					</svg>
				</div>
			</div>
			{(skipTitle === undefined || skipTitle === false) && <div className="mt-2 font-semibold">{video.title}</div>}
		</>
	);
};

export default VideoTile;
